@font-face {
	font-family: 'Rische-Light';
	src: local('Rische-Light')
		url('./fonts/Rische-Light.woff') format('woff')
}

@font-face {
	font-family: 'Rische-Regular';
	src: local('Rische-Regular')
		url('./fonts/Rische-Regular.woff') format('woff')
}

@font-face {
	font-family: 'Rische-Medium';
	src: local('Rische-Medium'),
		url('./fonts/Rische-Medium.woff') format('woff')
}

.main-container {
  position: relative;
  height: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container-content {
  border-top:1px solid rgba(0, 0, 0, 0.378);
  border-bottom: 1px solid rgba(0, 0, 0, 0.378);
  display: flex;
  width: 100%;
  height: 95%;
}

.main-container::before {
  content: "";
  position: relative;
  background-image: url('./assets/imgs/white-dust.png');
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: .25;
}

.section-1-border {
  border-right: 2px solid rgba(0, 0, 0, 0.176);
  flex:.15;
}

.main-container-photography-grid {
  flex:5
}

.section-2-border {
  border-left: 2px solid rgba(0, 0, 0, 0.176);
  flex:.15;

}

.main-container-section-1 {
  display: flex;
  flex-direction: column;
  flex:1.5;
  width: 100%;
  height:100%;
  border-right: 2px solid rgba(0, 0, 0, 0.176);
}

.main-container-section-2 {
  display: flex;
  flex:4;
  width: 100%;
  height:100%;
}

.main-container-title {
  margin-top:40px;
}

.main-container-name,
.main-container-quote {
  margin-left: 35px;
}

.main-container-quote {
  margin-top:25px;
  width:60%;
}

.name-header {
  position: relative;
  font-family: 'Rische-Light';
  font-size: 60px;
  font-weight:lighter;
  color: rgb(64, 64, 64) !important;
}

.quote-text {
  position: relative;
  font-family: 'Rische-Light';
  font-style: italic;
  font-size: 14px;
  font-weight:lighter;
  color: rgb(64, 64, 64) !important;
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 3px;
}

.main-container-photography-grid {
  height: 100%;
  overflow: auto;
}

.main-container-photography-grid::-webkit-scrollbar {
  display: none;
}

.mobile-container-view {
  flex:1;
  width: 100%;

}

.app-loader {
  height: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width:750px){
  .main-container-content {
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    border-left: 2px solid rgba(0, 0, 0, 0.176);
    border-right: 2px solid rgba(0, 0, 0, 0.176);
  }
  .main-container-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-container-photography-grid {
    height: 100%;
    margin: 0 auto;
    overflow: auto;
  }

  .main-container-name,
  .main-container-quote {
    margin-left: 0px;
  }

  .main-container-section-1 {
    flex:.75;
  }
  
  .main-container-quote{
    text-align: center;
  }

}