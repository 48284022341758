.contact-container {
	position: relative;
	display: flex;
	height: 100%;
	align-items: center;
}

.email-contact-container label,
.instagram-contact-container label{
	margin-left: 35px;
}

.email-contact-container  {
	margin-bottom: 7px;
}

.contact-text {
	font-family: 'Rische-Light';
	font-size: 18px;
	font-weight:lighter;
	color: rgb(64, 64, 64) !important;
	line-height: 1.4;
}


@media (max-width:750px){
	.contact-container {
		flex-direction: column;
		justify-content: start;
		height: 100%;
		flex:3;
	}

	.contact-content-container {
		margin: 0 auto;
	}

	.email-contact-container label,
	.instagram-contact-container label{
		margin-left: 0px;
	}	

}