.slideshow-container {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3 !important;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(220, 220, 220, 0.65);
	overflow: hidden;
}

.slideshow-content-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-bottom: 20px;
	overflow: hidden;
}

.slideshow-header-container {
	width: 100%;
	display:flex;
	justify-content: right;
	animation:none;
	animation: none !important;
}

.x-icon-button {
	cursor: pointer;
	margin-right: 15px;
	margin-top: 20px;
	z-index: 1;
	animation:none;
}

.slideshow-exit-button-container {
	animation: none !important;
}

.slideshow-body-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.slideshow-image-container {
	flex:1;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fadeIn 2s;
	z-index: 1;
}

.slideshow-left-button-container {
	flex:1;
	display: flex;
	align-items: center;
	height: 100%;
}

.slideshow-right-button-container {
	flex:1;
	display: flex;
	justify-content: right;
	align-items: center;
	height: 100%;
}

.arrow-button-text {
	position: relative;
	font-family: 'Rische-Light';
	font-size: 36px;
	font-weight:bold;
	color: rgb(47, 47, 47) !important;
	cursor: pointer;
	z-index: 10;
}

.slideshow-left-button-container .arrow-button-text {
	margin-left: 55px;
}

.slideshow-right-button-container .arrow-button-text {
	margin-right: 55px;
}

.slideshow-img {
	width: 85%;
}

@media (max-width:750px){
	.slideshow-img {
		width: 220%;
	}
	.slideshow-left-button-container .arrow-button-text {
		margin-left: 10px;
	}
	
	.slideshow-right-button-container .arrow-button-text {
		margin-right: 10px;
	}
}