.slide-left {
	animation: .8s slide-left;
}

@keyframes slide-left {
	from {
	  margin-left: 100%;
	}
	to {
	  margin-left: 0%;
	}
  }
  
.navigator-container {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.navigation-link-container{
	margin-left: 35px;
	padding-top: 8px;
}

.navigation-link {
	font-size: 20px;
	cursor: pointer;
	color: rgb(64, 64, 64) !important;
}

.link-underline {
	text-decoration: underline;
	text-decoration-thickness: 1px;
}

.navigator-container-mobile {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.navigation-mobile-link-container {
	padding-top: 20px;
	padding-bottom: 15px;
}

.mobile-menu-overlay {
	position: fixed;
	display: flex;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3 !important;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.697);
	overflow: hidden;
}

.mobile-menu-container {
	display: flex;
	justify-content: right;
	position: relative;
	width: 100%;
	height: 100%;
}

.mobile-menu-links-container {
	width: 50%;
	background-color: white;
}

.mobile-menu-links-list {
	width: 100%;
	margin-top: 55px;
}

.mobile-x-button-icon-container {
	display: flex;
	justify-content: right;
}

.mobile-nav-x {
	cursor: pointer;
	margin-top: 20px;
	margin-right: 20px;
}