@keyframes fadeIn {
	0% { 
		opacity: 0; 
		margin-top:40px;
	}
	100% { 
		opacity: 1; 
		margin-top:0px;
	}
}

.portfolio-container {
	position: relative;
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fadeIn 2s;
	cursor: pointer;
}