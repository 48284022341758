.about-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	animation:  fadeIn 2s;
}

.about-content-container,
.about-portrait-container {
	flex:1;
}

.about-portrait-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90%;
}

.about-content-container {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
}

.about-content-header-container {
	margin-left: 35px;
	margin-bottom: 20px;
}

.about-header-text {
	font-family: 'Rische-Light';
	font-size: 36px;
	font-weight:lighter;
	color: rgb(64, 64, 64) !important;
}

.about-desc-text {
	font-family: 'Rische-Light';
	font-size: 16px;
	font-weight:lighter;
	color: rgb(64, 64, 64) !important;
	line-height: 1.4;
}

.about-content-desc-container {
	height:100%;
	margin-left: 35px;
}

.self-portrait {
	width: 70%;
}

@media (max-width:750px){
	.about-container {
		flex-direction: column;
	}

	.about-content-header-container {
		margin-left: 0px;
		margin-top: 15px;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.about-content-container {
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.about-content-desc-container {
		margin-left: 0px;
		margin: 0 auto;
		width: 92%;
		display: flex;
		text-align: center;
		justify-content: center;
	}
	.self-portrait {
		width: 100%;
	}
}